import { Button, FlexBox, TextField, theme } from '@rario/shared-components'
import React from 'react'
import Link from 'next/link'
import { PLATFORM_ORIGIN } from 'config'
import { ThemeEnum } from 'interfaces'
import { getCDNUrl } from 'shared-components/utils'

const HomeLandingFirstFold: React.FunctionComponent = () => {
  return (
    <FlexBox
      position={'relative'}
      flexDirection={'column'}
      zIndex={999}
      height={PLATFORM_ORIGIN === ThemeEnum.RARIO ? '580px' : '520px'}
      backgroundImage={`url(${getCDNUrl('/App/images/rario-landing/first-fold-bg-v8.png')})`}
      backgroundPosition="top"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      justifyContent={'flex-end'}
      pb={40}
    >
      <FlexBox
        px={'10px'}
        left={0}
        right={0}
        top={PLATFORM_ORIGIN === ThemeEnum.RARIO ? 85 : 105}
        flexDirection={'column'}
        textAlign={'center'}
        alignItems={'center'}
        position={'absolute'}
      >
        <TextField
          fontFamily={theme.fonts.primary}
          fontWeight="800"
          fontSize="23px"
          lineHeight="28.06px"
          textAlign="center"
          mb="43px"
          color={theme.colors.cosmicGreen}
        >
          LIVE NOW!
        </TextField>
      </FlexBox>

      <FlexBox px={'20px'} flexDirection={'column'} textAlign={'center'} alignItems={'center'}>
        <TextField
          fontWeight="400"
          fontSize="14px"
          lineHeight="18px"
          textAlign="center"
          whiteSpace="pre-line"
          mb="20px"
        >
          Rario Buyback at base price is{' '}
          <TextField
            fontWeight="500"
            fontSize="14px"
            lineHeight="18px"
            textAlign="center"
            color={theme.colors.cosmicGreen}
          >
            now live{'\n'}
          </TextField>
          Log in to participate.
        </TextField>

        <Link href={'/login'} passHref>
          <a id={'login-btn'}>
            <Button
              primaryEdges
              cornerIndentation="10px"
              name={'LOGIN'}
              mx={'12px'}
              width={'130px'}
              variant={'dynamic'}
              fontSize={'14px'}
            />
          </a>
        </Link>
      </FlexBox>
    </FlexBox>
  )
}

export default HomeLandingFirstFold
